import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";

const initialState = () => ({
  hierarchy: [],
  location: {},
  locations: [],
  organization: {},
  organizations: [],
  locationErrors: {},
  organizationErrors: {},
});

export default {
  state: initialState(),
  actions: {
    // async loadHierarchy({ commit, state }, payload = {}) {
    //   if (!isEmpty(state.hierarchy) && !("_forceload" in payload)) return;
    //   let res = await this._vm.$api.get("Hierarchy", payload);
    //   if (res.status === 200) {
    //     commit("setHierarchy", res.data.data);
    //   }
    // },

    // eslint-disable-next-line no-empty-pattern
    async fetchLocations({}, payload = {}) {
      let res = await this._vm.$api.get("Location", payload);
      return res.status === 200 ? res.data.data : [];
    },

    async loadLocations({ commit, state }, payload = {}) {
      if (!("_forceload" in payload) && !isEmpty(state.locations)) return;
      let res = await this._vm.$api.get("Location", payload);
      if (res.status === 200) {
        commit("setLocations", res.data.data);
      }
    },
    async loadLocation({ commit, getters }, payload = {}) {
      if (payload.id === getters.getActiveLocation && !("_forceload" in payload)) return;
      let res = await this._vm.$api.get("Location", payload);
      if (res.status === 200) {
        commit("setLocation", res.data.data);
        commit("setOrganization", res.data.data.organization);
      }
    },

    async loadOrganization({ state, commit }, payload = {}) {
      const obj = state.organizations.find((o) => o.id === payload.id);
      if (obj) commit("setOrganization", obj);
    },

    async addOrganization({ dispatch }, payload = {}) {
      let res = await this._vm.$api.post("Organization", payload);
      return dispatch("processOrganization", res);
    },

    async editOrganization({ dispatch }, payload = {}) {
      let res = await this._vm.$api.put("Organization", payload);
      return dispatch("processOrganization", res);
    },

    async addLocation({ dispatch }, payload = {}) {
      let res = await this._vm.$api.post("Location", payload);
      return dispatch("processLocation", res);
    },

    async editLocation({ dispatch }, payload = {}) {
      let res = await this._vm.$api.put("Location", payload);
      return dispatch("processLocation", res);
    },

    async toggleLocationUsers({ commit }, payload = {}) {
      let res = await this._vm.$api.post("LocationUser", payload);
      if (res.status === 200) {
        commit("setLocation", res.data.data.location);
        commit("setUsers", res.data.data.users);
      }
      return res.status === 200 || res.status === 201;
    },

    processLocation({ commit }, res) {
      commit("setLocationErrors", "errors" in res.data ? res.data.errors : {});
      if (res.status === 200 || res.status === 201) {
        commit("setLocation", res.data.data.location);
        commit("setHierarchy", res.data.data.hierarchy);
      }
      return res.status === 200 || res.status === 201;
    },

    processOrganization({ commit }, res) {
      commit("setOrganizationErrors", "errors" in res.data ? res.data.errors : {});
      if (res.status === 200 || res.status === 201) {
        commit("setOrganization", res.data.data.organization);
        commit("setHierarchy", res.data.data.hierarchy);
      }
      return res.status === 200 || res.status === 201;
    },
  },
  mutations: {
    setOrganization(state, data) {
      state.organization = data;
    },
    setOrganizations(state, data) {
      state.organizations = data;
    },
    setLocation(state, data) {
      state.location = data;
    },
    setLocations(state, data) {
      state.locations = data;
    },
    setHierarchy(state, data) {
      state.hierarchy = data;
    },
    setOrganizationErrors(state, data) {
      state.organizationErrors = data;
    },
    setLocationErrors(state, data) {
      state.locationErrors = data;
    },
    ResetOrganization(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },

  getters: {
    getHierarchy: (state) => sortBy(state.hierarchy, [(org) => org.name.toLowerCase()]),
    getLocation: (state) => state.location,
    getLocations: (state) => sortBy(state.locations, ["name"]),
    getOrganization: (state) => state.organization,
    getOrganizations: (state) => sortBy(state.organizations, ["name"]),
    getLocationErrors: (state) => state.locationErrors,
    getOrganizationErrors: (state) => state.organizationErrors,
    getActiveLocation: (state) => (state.location.id ? state.location.id : null),
    getActiveOrganization: (state) => (state.organization.id ? state.organization.id : null),
    getLocationIdIfConsumer: (state) => {
      const location = state.hierarchy.find((u) => u.organization_type === "consumer");
      if (location && location.children && location.children.length > 0) {
        return location.children[0].id;
      }
      return null;
    },
  },
};
